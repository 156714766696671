.App {
    font-family: sans-serif;
    text-align: center;
}


/* For pdf */

.react-pdf__Page {
    margin-top: 10px;
}

.react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
    padding: 0px;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
}


/* For all pages */

.all-page-container {
    height: 100%;
    max-height: 800px;
    overflow: auto;
}